<template>
  <svg viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.62911 19.3094V10.6883H10.6685L11.1236 7.3284H7.62902V5.18328C7.62902 4.21053 7.91271 3.54765 9.37793 3.54765L11.2466 3.54681V0.541799C10.9234 0.500927 9.81406 0.409424 8.52362 0.409424C5.8293 0.409424 3.98472 1.97516 3.98472 4.85063V7.3284H0.9375V10.6883H3.98472V19.3093H7.62911V19.3094Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconFacebook'
}
</script>
